<template>
  <cs-view single-view>
    <template #content>
      <v-expansion-panels v-model="activePanels" variant="accordion" multiple>
        <v-expansion-panel>
          <v-expansion-panel-title class="title-1">Helix Buttons</v-expansion-panel-title>
          <v-expansion-panel-text>
            <helix-buttons />
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-title class="title-1">Helix Table</v-expansion-panel-title>
          <v-expansion-panel-text>
            <hx-table
              :title="title"
              :items="items"
              :headers="headers"
              :loading="loading"
              :show-select="showSelect"
              :items-per-page="itemsPerPage"
              :items-per-page-options="itemsPerPageOptions"
            />
            <v-container>
              <v-row>
                <v-switch
                  v-model="loading"
                  :label="`Loading: ${loading.toString()}`"
                  color="primary"
                ></v-switch>
                <v-spacer></v-spacer>
                <v-switch
                  v-model="showSelect"
                  :label="`Show Select Button: ${showSelect.toString()}`"
                  color="primary"
                ></v-switch>
                <v-spacer></v-spacer>
                <v-switch
                  v-model="showTitle"
                  :label="`Show title: ${showTitle.toString()}`"
                  color="primary"
                  @change="toggleTitle"
                ></v-switch>
              </v-row>
            </v-container>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </cs-view>
</template>

<script>
import HelixButtons from '@/components/helix/HelixButtons';

const dummyData = [...Array(100)].map((title, index) => {
  return {
    id: index,
    portal: 'aacg-complispacelearn.mygo1.com',
    title: 'About Me - New Standards – Introduction',
    type: 'Video',
    learningObjectID: '92381234',
    assigned: 3,
    pending: 0,
    notStarted: 0
  };
});
export default {
  name: 'HelixComponents',
  components: {
    HelixButtons
  },
  data() {
    return {
      activePanels: [0, 1],
      title: undefined,
      showTitle: false,
      items: dummyData,
      showSelect: false,
      itemsPerPage: 10,
      itemsPerPageOptions: [10, 20, 30],
      headers: [
        {
          title: 'Portal',
          value: 'portal',
          key: 'portal',
          minWidth: 275
        },
        { title: 'Title', value: 'title', key: 'title', minWidth: 350 },
        { title: 'Type', value: 'type', key: 'type' },
        {
          title: 'Learning Object ID',
          value: 'learningObjectID',
          key: 'learningObjectID',
          minWidth: 175
        },
        { title: 'Assigned', value: 'assigned', key: 'assigned' },
        { title: 'Pending', value: 'pending', key: 'pending' },
        { title: 'Not Started', value: 'notStarted', key: 'notStarted' }
      ],
      loading: false
    };
  },
  methods: {
    toggleTitle() {
      if (this.showTitle) {
        this.title = 'Helix Table Title';
      } else {
        this.title = undefined;
      }
    }
  }
};
</script>
