<template>
  <v-theme-provider :theme="appTheme">
    <cs-app
      :show-header="isLoggedIn"
      :side-nav-width="sideNavWidth"
      :customer-logo-uri="customerLogo"
      :app-logo-uri="appLogo"
      customer-name="Complispace"
      :feedback-btn="feedbackBtn"
      :help-btn="helpBtn"
      :search-btn="searchBtn"
      :update-btn="updateBtn"
      :view-btn="viewBtn"
      :version="version"
      :header-profile="headerProfile"
      :version-in-user-profile="version"
      @feedback-btn-click="onFeedbackBtn"
      @search-btn-click="onSearchBtn"
      @view-btn-click="onViewBtn"
    >
      <template #header-menu>
        <slot-indicator
          v-if="headerType === 0"
          slot-name="Menu"
          width="auto"
          height="auto"
        ></slot-indicator>
        <cs-button v-if="headerType === 1" primary large label="Create"></cs-button>
      </template>
      <template #header-left>
        <slot-indicator
          v-if="headerType === 0"
          slot-name="Call to action"
          width="auto"
          height="auto"
        ></slot-indicator>
      </template>
      <template v-if="headerType !== 2" #header-right>
        <v-select
          id="header-style-select"
          v-model="currentTheme"
          theme="dark"
          density="compact"
          flat
          :items="[
            { title: 'Default Theme', value: 0 },
            { title: 'Ce Theme', value: 1 }
          ]"
          style="z-index: 20; max-width: 200px; align-self: center"
          class="header-style-select text-white"
          hide-details
          @update:model-value="onChangeTheme"
        ></v-select>
      </template>
      <template v-if="headerType !== 2" #header-right-2>
        <v-select
          id="header-style-select"
          theme="dark"
          density="compact"
          flat
          :items="[
            { title: 'Sample 1', value: 0 },
            { title: 'Sample 2', value: 1 },
            { title: 'Sample 3', value: 2 }
          ]"
          :model-value="headerType"
          style="z-index: 20; max-width: 200px; align-self: center"
          class="header-style-select text-white"
          hide-details
          @update:model-value="onHeaderStyleChange"
        ></v-select>
      </template>
      <template #header-help-menu>
        <slot-indicator slot-name="Call to action" width="auto" height="auto"></slot-indicator>
      </template>

      <template v-if="headerType === 1" #header-settings-menu>
        <slot-indicator slot-name="Call to action" width="auto" height="auto"></slot-indicator>
      </template>

      <template #side-nav>
        <slot-indicator slot-name="Call to action" width="auto" height="20rem">
          <template #content>
            <v-container fluid>
              <v-row align="center">
                <v-col class="d-flex">
                  <v-select
                    v-model="viewType"
                    :items="items"
                    density="compact"
                    variant="solo"
                    label="Select View Type"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col md class="d-flex">
                  <cs-button
                    width="100%"
                    label="Hide Side Nav"
                    @click.stop.prevent="onHideSideNav"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-container style="position: absolute; bottom: 0">
              <v-row>
                <v-col class="col-12 version">cs-design-systems v{{ csDesignSystemVersion }}</v-col>
              </v-row>
            </v-container>
          </template>
        </slot-indicator>
      </template>

      <template #router-view>
        <router-view v-slot="{ Component }">
          <v-slide-y-transition>
            <component :is="Component" />
          </v-slide-y-transition>
        </router-view>
      </template>

      <template #footer>
        <div class="content">
          <v-container>
            <v-row>
              <v-img :src="csLogo" class="cs-logo"> </v-img>
            </v-row>
            <v-row>
              <span>
                &copy;{{ copyRightYearRange }} CompliSpace (v{{ version }}). All rights reserved.
              </span>
            </v-row>
          </v-container>
        </div>
      </template>
    </cs-app>
  </v-theme-provider>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import {
  CSBase,
  axios,
  CSHeaderButtonProps,
  CSHeaderActiveButtonProps,
  CSHeaderProfileProps
} from '@complispace/cs-design-system';
import tinykeys from 'tinykeys';
import csLogo from './assets/complispace-logo.svg';
import { version, dependencies } from '../package.json';
import * as types from './store/mutationTypes';

export default {
  name: 'App',

  extends: CSBase,

  data() {
    return {
      appTheme: 'light',
      csLogo,
      items: [
        {
          title: 'single-view',
          value: 'single-view'
        },
        {
          title: 'wide-nav',
          value: 'wide-nav'
        },
        {
          title: 'narrow-nav',
          value: 'narrow-nav'
        },
        {
          title: 'Apex Definitions',
          value: 'apex-definitions'
        },
        {
          title: 'Compliance GraphQL Mediator API',
          value: 'compliance-graphql-mediator-api'
        },
        {
          title: 'Form Controls',
          value: 'form-controls'
        },
        {
          title: 'Helix Components',
          value: 'helix-components'
        },
        {
          title: 'HelixifyVuetifyDefinitions',
          value: 'helixify-vuetify-definitions'
        },
        {
          title: 'Internationalization',
          value: 'internationalization'
        }
      ],
      selectedView: 'single-view',
      hideSideNav: false,
      headerType: 0,
      viewActive: false,
      currentTheme: 0
    };
  },

  computed: {
    ...mapGetters({
      isLoggedIn: 'cs-session/isLoggedIn',
      user: 'cs-session/user'
    }),

    ...mapState({
      viewTypeStore: 'view-type'
    }),

    viewType: {
      get() {
        return this.viewTypeStore.viewType;
      },

      set(value) {
        this.setPageViewType(value);
        this.changeRoute(value);
      }
    },

    fingerBtn() {
      return {
        action: this.fingerBtnAction
      };
    },

    feedbackBtn() {
      const id = 'id-for-feedback';
      const toolTip = 'put your feedback here';
      return new CSHeaderButtonProps(id, toolTip);
    },

    searchBtn() {
      const id = 'id-for-search';
      const toolTip = 'put your search here';
      return new CSHeaderActiveButtonProps(id, toolTip);
    },

    updateBtn() {
      if (this.headerType === 0) {
        return null;
      }
      const id = 'check-for-updates';
      const toolTip = 'Check for Updates';
      const action = this.onUpdateBtn;
      return new CSHeaderButtonProps(id, toolTip, undefined, action);
    },

    viewBtn() {
      const id = 'id-for-view';
      const toolTip = 'Administrator View';
      const active = this.viewActive;
      return new CSHeaderActiveButtonProps(id, toolTip, undefined, undefined, active);
    },

    helpBtn() {
      const id = 'id-for-help';
      const toolTip = 'Navigate to google for help';
      const href = 'https://www.google.com';
      return new CSHeaderButtonProps(id, toolTip, href);
    },

    sideNavWidth() {
      return this.isLoggedIn && !this.hideSideNav ? 300 : 0;
    },

    version() {
      return version;
    },

    copyRightYearRange() {
      const startYear = '2018';
      return `${startYear}-${new Date().getFullYear().toString()}`;
    },
    csDesignSystemVersion() {
      return dependencies['@complispace/cs-design-system'];
    },

    appLogo() {
      let logoUri = '';
      if (this.headerType === 1) {
        logoUri = '/samples/policyConnect-logo.png';
      } else {
        logoUri = '';
      }
      return logoUri;
    },

    customerLogo() {
      const s3ImgPath =
        'https://s3-us-west-2.amazonaws.com/develop.contentengine.compliengine.com/client-assets';
      let logoUri = '';
      if (this.headerType === 1) {
        logoUri = `${s3ImgPath}/212/0c82048c1f8ff60c8ee2c2169597bbb1-small.png`;
      } else {
        logoUri = `${s3ImgPath}/212/db2d3267f4d3c75aaedbd8351433c04e-small.png`;
      }
      return logoUri;
    },

    headerProfile() {
      return new CSHeaderProfileProps(
        {
          givenName: this.user?.givenName || this.user?.name || '',
          surname: this.user?.surname || '',
          email: this.user?.email || ''
        },
        {
          logout: {
            action: this.logout
          }
        }
      );
    }
  },

  created() {
    this.setViewTitle('CS Design System Documentations');
  },

  async mounted() {
    await this.sampleAxios();

    this.setPDFServiceGraphqlApiConfig({
      endpoint: process.env.VUE_APP_COMPLIANCE_GRAPHQL_MEDIATOR_ENDPOINT,
      region: process.env.VUE_APP_COMPLIANCE_GRAPHQL_MEDIATOR_REGION
    });
    document.title = this.$store.getters['appearance/viewTitle'] || 'cs-design-system-docs';
    tinykeys(window, {
      'Control+Shift+e': () => {
        this.$store.commit(
          types.ENGINEERING_SET_TOGGLE,
          this.$store.getters['engineering/enabled']
        );
      }
    });
  },

  methods: {
    ...mapMutations({
      setPageViewType: types.VIEW_TYPE_SET_PAGE_VIEW_TYPE,
      setPDFServiceGraphqlApiConfig: types.COMPLIANCE_GRAPHQL_MEDIATOR_SET_CONFIG
    }),

    fingerBtnAction() {
      // eslint-disable-next-line no-alert
      alert('finger button action executed!');
    },

    onFeedbackBtn() {
      // eslint-disable-next-line no-alert
      alert('feedback button action executed!');
    },

    onSearchBtn() {
      // eslint-disable-next-line no-alert
      alert('search button action executed!');
    },

    onUpdateBtn() {
      // eslint-disable-next-line no-alert
      alert('update button action executed!');
    },

    onViewBtn() {
      this.viewActive = !this.viewActive;
      if (this.viewActive) {
        this.headerType = 1;
      } else {
        this.headerType = 0;
      }
    },
    onHeaderStyleChange(value) {
      this.headerType = value;
    },

    onChangeTheme(useCeTheme) {
      let theme = 'light';
      if (useCeTheme) {
        theme = 'ce';
      }
      this.appTheme = theme;
    },

    changeRoute(viewType) {
      switch (viewType) {
        case 'single-view':
          if (this.$route.path === '/') {
            this.$router.go();
          } else {
            this.$router.push('/');
          }
          break;
        case 'wide-nav':
          if (this.$route.path === '/wide-nav') {
            this.$router.go();
          } else {
            this.$router.push('/wide-nav');
          }
          break;
        case 'narrow-nav':
          if (this.$route.path === '/narrow-nav') {
            this.$router.go();
          } else {
            this.$router.push('/narrow-nav');
          }
          break;
        case 'apex-definitions':
          this.onShowApexDefinitions();
          break;
        case 'form-controls':
          this.onShowCsFormControls();
          break;
        case 'helix-components':
          this.onShowHelixComponents();
          break;
        case 'helixify-vuetify-definitions':
          this.onShowHelixifyVuetifyDefinitions();
          break;
        case 'compliance-graphql-mediator-api':
          this.onShowComplianceGraphQLMediatorAPI();
          break;
        case 'internationalization':
          this.onShowInternationalization();
          break;
        default:
          if (this.$route.path === '/') {
            this.$router.go();
          } else {
            this.$router.push('/');
          }
      }
    },

    onHideSideNav() {
      this.hideSideNav = true;
    },

    onShowApexDefinitions() {
      if (this.$route.path === '/apex-definitions') {
        this.$router.go();
      } else {
        this.$router.push('/apex-definitions');
      }
    },

    onShowCsFormControls() {
      if (this.$route.path === '/form-controls') {
        this.$router.go();
      } else {
        this.$router.push('/form-controls');
      }
    },

    onShowHelixComponents() {
      if (this.$route.path === '/helix-components') {
        this.$router.go();
      } else {
        this.$router.push('/helix-components');
      }
    },

    onShowHelixifyVuetifyDefinitions() {
      if (this.$route.path === '/helixify-vuetify-definitions') {
        this.$router.go();
      } else {
        this.$router.push('/helixify-vuetify-definitions');
      }
    },

    onShowComplianceGraphQLMediatorAPI() {
      if (this.$route.path === '/compliance-graphql-mediator-api') {
        this.$router.go();
      } else {
        this.$router.push('/compliance-graphql-mediator-api');
      }
    },

    onShowInternationalization() {
      if (this.$route.path === '/internationalization') {
        this.$router.go();
      } else {
        this.$router.push('/internationalization');
      }
    },
    async sampleAxios() {
      const url = `${window.location.origin}/favicon.ico`;
      await axios.get(url, {});
    },
    logout() {
      this.$store.dispatch('cs-session/logout');
    }
  }
};
</script>

<style>
.v-footer.theme--light.v-sheet {
  background-color: white;
}

.v-footer {
  justify-content: space-around;
}
</style>
<style scoped>
.cs-logo {
  width: 200px;
  height: 40px;
}
.version {
  font-size: 9pt;
  padding-left: 18px;
  color: black;
}
</style>
