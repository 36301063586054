import { createWebHistory, createRouter } from 'vue-router';
import HomePage from '../views/HomePage';
import WideNav from '../views/WideNav';
import NarrowNav from '../views/NarrowNav';
import ApexDefinitions from '../views/ApexDefinitions';
import FormControls from '../views/FormControls';
import HelixComponents from '../views/HelixComponents';
import HelixifyVuetifyDefinitions from '../views/HelixifyVuetifyDefinitions';
import ComplianceGraphQLMediatorAPI from '../views/ComplianceGraphQLMediatorAPI';
import InternationalizationPage from '../views/InternationalizationPage';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/wide-nav',
    name: 'WideNav',
    component: WideNav
  },
  {
    path: '/narrow-nav',
    name: 'NarrowNav',
    component: NarrowNav
  },
  {
    path: '/apex-definitions',
    name: 'apex-definitions',
    component: ApexDefinitions
  },
  {
    path: '/form-controls',
    name: 'form-controls',
    component: FormControls
  },
  {
    path: '/helix-components',
    name: 'helix-components',
    component: HelixComponents
  },
  {
    path: '/helixify-vuetify-definitions',
    name: 'helixify-vuetify-definitions',
    component: HelixifyVuetifyDefinitions
  },
  {
    path: '/compliance-graphql-mediator-api',
    name: 'compliance-graphql-mediator-api',
    component: ComplianceGraphQLMediatorAPI
  },
  {
    path: '/internationalization',
    name: 'internationalization',
    component: InternationalizationPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
